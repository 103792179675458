.prof-table.d-flex {
  display: flex;
  flex-direction: column;
  max-width: 51rem;
  margin-bottom: 4rem;
}

.prof-table-row:last-child {
  border-bottom: 1px solid var(--button-bg-color);
}

.prof-table.d-flex .prof-table-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--button-bg-color);
}

.prof-description-value {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

.prof-table.d-flex .prof-table-row:last-child {
  border: none;
}

.prof-table-col-left {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3.3rem;
}

.prof-table-col-left .prof-description-value {
  color: var(--main-body-color);
}

.prof-table-col-right {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3.3rem;
}

.prof-table-col-right .prof-description-value {
  color: var(--main-input-placeholder);
}

.prof-table.d-flex.data-forma {
  margin-bottom: 0;
}
