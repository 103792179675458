.error {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error-p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
}

.error-p-a {
  color: var(--input-border-bottom-color);
}

.error-p-a:hover {
  color: var(--main-input-placeholder);
}
