@charset "UTF-8";

:root {
  --main-input-placeholder-color: #999;
  --main-input-bg: #efefef;
  --main-body-color: #1e1e1e;
  --input-border-bottom-color: #3369f3;
  --button-bg-color: #eaeaea;
  --button-color: #fff;
  --section-bg-color: #fbfbfb;
  --a-color: #f00;
}

button {
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--main-input-placeholder);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
}

a {
  text-decoration: none;
  outline: none;
}

a:hover {
  color: var(--main-body-color);
}

a:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

body,
html {
  width: 100%;
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.2rem;
  color: var(--main-body-color);
}

input {
  border: none;
  background: var(--main-input-bg);
  height: 3.2rem;
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
  width: 100%;
  border-radius: 5px;
  line-height: 1.2rem;
}

input:focus {
  outline: none;
  border: none;
  background: none;
  border-bottom: 1px solid var(--input-border-bottom-color);
  border-radius: 0;
  color: var(--main-body-color);
  font-size: 1.3rem;
}

section#hero {
  display: flex;
  flex-wrap: row nowrap;
}

form * {
  color: var(--main-body-color);
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: var(--main-body-color);
  margin: 0 auto 4rem;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: var(--main-body-color);
  margin-bottom: 7rem;
}

.warn {
  color: red;
  order: 0;
  opacity: 0;
  height: 0;
}

.all-button {
  display: inline-block;
  border: none;
  color: var(--button-color);
  cursor: pointer;
  outline: none;
  width: auto;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.3rem;
  text-transform: none;
  letter-spacing: 1px;
  padding: 0 2rem;
  max-height: 3.7rem;
  white-space: nowrap;
  line-height: 3.7rem;
  background: var(--input-border-bottom-color);
  border-radius: 8px;
}

.all-button:hover {
  background: var(--button-bg-color);
  color: var(--main-body-color);
}

.main {
  width: 100%;
  height: 100%;
}

.d-none {
  display: none;
}
