.auth-reg-form {
  width: 34rem;
  background: var(--button-color);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border: 2px solid #ffffff1a;
  border-radius: 12px;
  flex-direction: column;
  padding: 5rem 3rem 3rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 6px #00000024;
}

.auth-reg-form .login {
  height: 46rem;
}

.auth-reg-form .inp-holder {
  width: 100%;
  flex-direction: column-reverse;
  margin-bottom: 2.5rem;
  display: flex;
}

.auth-reg-form .inp-holder .label-for {
  color: var(--main-input-placeholder);
  order: 2;
  margin: 0;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
  display: block;
}

.auth-reg-form-input {
  height: 2.8rem;
  width: 100%;
  background-color: var(--button-color);
  color: var(--main-body-color);
  border-bottom: 1px solid var(--input-border-bottom-color);
  border-radius: 0;
  order: 2;
  margin-top: 4px;
  margin-bottom: .7rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.8rem;
  display: block;
}

.auth-reg-form-button {
  width: 100%;
  color: var(--button-color);
  cursor: pointer;
  text-align: center;
  text-transform: none;
  letter-spacing: 1px;
  max-height: 3.7rem;
  white-space: nowrap;
  background: var(--input-border-bottom-color);
  border: none;
  border-radius: 8px;
  outline: none;
  margin-top: auto;
  margin-bottom: 1.4rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 3.7rem;
  text-decoration: none;
  display: inline-block;
}

.auth-reg-form-button:hover {
  background: var(--button-bg-color);
  color: var(--main-body-color);
}

.auth-reg-form .no-akkaunt-link {
  text-align: center;
  color: var(--input-border-bottom-color);
  display: block;
}

.auth-reg-form .no-akkaunt-link:hover {
  color: var(--main-input-placeholder);
}

.profile-content {
  flex: 1 0 auto;
}

.profil-forma {
  width: 51rem;
  height: auto;
  min-height: 62rem;
  background: var(--button-color);
  box-shadow: none;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border: none;
  border-radius: 0;
  flex-direction: column;
  padding: 5rem 3rem 3rem;
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profil-forma-button {
  color: var(--button-color);
  cursor: pointer;
  width: auto;
  text-align: center;
  text-transform: none;
  letter-spacing: 1px;
  max-height: 3.7rem;
  white-space: nowrap;
  background: var(--input-border-bottom-color);
  border: none;
  border-radius: 8px;
  outline: none;
  margin-top: auto;
  margin-bottom: 1.4rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 3.7rem;
  text-decoration: none;
  display: inline-block;
}

.profil-forma-button:hover {
  background: var(--button-bg-color);
  color: var(--main-body-color);
}

.out-profil {
  padding-top: 1.5rem;
}

.out-profil-link {
  color: var(--a-color);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}

.profil-modal-form {
  height: auto;
  width: 34rem;
  background: var(--button-color);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border: 2px solid #ffffff1a;
  border-radius: 12px;
  flex-direction: column;
  padding: 5rem 3rem 3rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 6px #00000024;
}

.profil-modal-form .inp-holder {
  width: 100%;
  flex-direction: column-reverse;
  margin-bottom: 2.5rem;
  display: flex;
}

.profil-modal-form-inp-holder-label {
  color: var(--main-input-placeholder);
  order: 2;
  margin: 0;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
  display: block;
}

.profil-modal-form-inp-holder-input {
  height: 2.8rem;
  width: 100%;
  background-color: var(--button-color);
  color: var(--main-body-color);
  border-bottom: 1px solid var(--input-border-bottom-color);
  border-radius: 0;
  order: 2;
  margin-top: 4px;
  margin-bottom: .7rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.8rem;
  display: block;
}

.profil-modal-form-button {
  color: var(--button-color);
  cursor: pointer;
  width: auto;
  text-align: center;
  text-transform: none;
  letter-spacing: 1px;
  max-height: 3.7rem;
  white-space: nowrap;
  background: var(--input-border-bottom-color);
  border: none;
  border-radius: 8px;
  outline: none;
  margin-top: auto;
  margin-bottom: 1.4rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 3.7rem;
  text-decoration: none;
  display: inline-block;
}

.profil-modal-form-button:hover {
  background: var(--button-bg-color);
  color: var(--main-body-color);
}

.profil-modal-overley {
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: #0006;
  margin: auto;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.profil-modal-overley.show {
  display: flex;
}

form.change-profil-forma {
  width: 51rem;
  height: auto;
  min-height: 62rem;
  background: var(--button-color);
  box-shadow: none;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border: none;
  border-radius: 0;
  flex-direction: column;
  padding: 5rem 3rem 3rem;
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

form.change-profil-forma-inp-holder {
  width: 100%;
  flex-direction: column-reverse;
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

form.change-profil-forma-inp-holder-label {
  color: var(--main-body-color);
  opacity: 1;
  order: 2;
  margin: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
  display: block;
  position: absolute;
  top: 13px;
}

form.change-profil-forma-inp-holder-input {
  height: 2.8rem;
  width: 100%;
  background-color: var(--button-color);
  color: var(--main-body-color);
  border-bottom: 1px solid var(--button-bg-color);
  text-align: right;
  border-radius: 0;
  order: 2;
  margin-top: 4px;
  margin-bottom: .7rem;
  padding: 0 0 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.8rem;
  display: block;
}

.change-profil-forma-button {
  color: var(--button-color);
  cursor: pointer;
  text-align: center;
  text-transform: none;
  letter-spacing: 1px;
  max-height: 3.7rem;
  white-space: nowrap;
  background: var(--input-border-bottom-color);
  width: 100%;
  border: none;
  border-radius: 8px;
  outline: none;
  margin-top: auto;
  margin-bottom: 1.4rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 3.7rem;
  text-decoration: none;
  display: block;
}

.change-profil-forma-button:hover {
  background: var(--button-bg-color);
  color: var(--main-body-color);
}

.change-profil-forma-footer {
  padding-top: 4rem;
}

.chat {
  width: 31rem;
  height: 100vh;
  background: #fbfbfb;
  border: none;
  border-right: 1px solid #eaeaea;
  flex-direction: column;
  flex: none;
  display: flex;
  position: relative;
}

.chat .top {
  min-height: 60px;
  width: 100%;
  z-index: 1;
  height: 11.1rem;
  background: #fbfbfb;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  padding: 2rem 1rem .2rem;
  position: absolute;
  top: 0;
  left: 0;
}

.chat .top:after {
  content: "";
  width: calc(100% - 2rem);
  height: 1px;
  background: #eaeaea;
  margin: 0 auto;
  position: absolute;
  bottom: -.25rem;
  left: 0;
  right: 0;
}

.chat-top-search-add {
  display: flex;
}

.chat-top-profil {
  width: 100%;
  text-align: right;
  color: #999;
  flex: none;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1px;
}

.chat-top-profil-link {
  color: #999;
}

.chat-top-profil-link:hover {
  color: #3369f3;
}

.chat-top-search {
  width: 88%;
  flex: none;
  margin-bottom: 1.2rem;
  padding-top: 2rem;
}

.chat-top-search ::placeholder {
  text-align: center;
}

.chat-add {
  cursor: pointer;
  margin-bottom: 1.2rem;
  padding-top: 2rem;
  padding-left: .5rem;
}

.chat-forma-search-input {
  font-family: "Font Awesome 5 Free", sans-serif;
}

.tumbler {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tumbler::-webkit-scrollbar {
  display: none;
}

.chat .tumbler {
  padding-top: 11.1rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.conversations {
  flex-direction: column;
}

.person {
  width: 100%;
  height: auto;
  min-height: 7.5rem;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  column-gap: 1rem;
  margin: 2px 0;
  padding: 0 10px;
  display: grid;
  position: relative;
}

.person:after {
  content: "";
  width: calc(100% - 2rem);
  height: 1px;
  background: #eaeaea;
  margin: 0 auto;
  position: absolute;
  bottom: -.25rem;
  left: 0;
  right: 0;
}

.person > .box {
  width: 50px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.person-box-image {
  border: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.person:hover {
  cursor: pointer;
  background-color: #e4edfd;
}

.person > .information {
  width: 19.3rem;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.person-information-username {
  margin-bottom: 4px;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3rem;
}

.person-information-message {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.person > .notice {
  width: 2rem;
  height: 100%;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.2rem 0;
  display: flex;
}

.person-notice-data {
  white-space: nowrap;
  max-width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  margin-bottom: auto;
  display: flex;
  overflow: visible;
}

.person-notice-data-time {
  text-align: right;
  color: #999;
  font-size: .9rem;
  font-weight: 500;
  line-height: 1.3rem;
}

.person-notice-point {
  width: 2rem;
  height: 2rem;
  text-align: center;
  color: #fff;
  background: #3369f3;
  border-radius: 50%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
}

.chat-content {
  max-height: 100vh;
  scrollbar-width: none;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 6.6rem;
  padding-bottom: 6.6rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chat-content .chat-content-header {
  width: 100%;
  height: 6.6rem;
  max-width: 100%;
  border-bottom: 1px solid #eaeaea;
  position: absolute;
  top: 0;
  left: 0;
}

.chat-content-header-person {
  width: 100%;
  height: 6.6rem;
  min-height: 6.6rem;
  align-items: center;
  column-gap: 0;
  margin: 0;
  padding: 0 2rem;
  display: flex;
  position: relative;
}

.chat-content-header-person-box {
  flex: 0 0 50px;
}

.chat-content-header-person-information {
  padding-left: 1rem;
}

.chat-content-header-person-notice {
  width: 2rem;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0;
  display: flex;
}

.chat-content .chat-content-footer {
  width: 100%;
  height: 6.6rem;
  max-width: 100%;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
}

#message-form {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  display: flex;
}

#message-form .input-holder {
  flex: 1 0 auto;
  padding: 0 2rem;
}

#message-form .input-holder:first-child {
  height: 100%;
  flex: 0 0 3.2rem;
  padding: 0;
  position: relative;
}

#message-form-file-input {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

#message-form #message {
  height: 3.2rem;
  background: #efefef;
  border-radius: 30px;
}

#message-form #message:focus {
  background: #fff;
  border-radius: 0;
}

#message-form .input-holder:last-child {
  flex: 0 0 3rem;
  padding: 0;
}

.button-send {
  width: 2.8rem;
  height: 2.8rem;
  box-shadow: none;
  background: #3369f3;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chat-content .chat-content-footer:after {
  content: "";
  width: calc(100% - 2rem);
  height: 1px;
  background: #eaeaea;
  margin: 0 auto;
  position: absolute;
  top: -.25rem;
  left: 0;
  right: 0;
}

.chat-content .content-holder {
  min-width: 100%;
  height: auto;
  background: #fff;
  flex-direction: column;
  padding: 3rem 2rem;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.chat-content .content-holder p.mess-date {
  width: 100%;
  text-align: center;
  color: #999;
  margin: 2rem auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.chat-content-holder-message-holder {
  padding: 0 2rem;
}

.chat-content-holder-message-holder-message-in {
  max-width: 40rem;
  height: auto;
  float: left;
  clear: both;
  margin: 0 auto 0 0;
  display: inline-block;
}

.chat-content-holder-message-holder-message-in .message-in-text {
  background: #f8f8f8;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 1.1rem;
  position: relative;
  overflow: hidden;
}

.chat-content-holder-message-holder-message-in-text-p {
  color: #1e1e1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}

.chat-content-holder-message-holder-message-in-text-time {
  text-align: right;
  color: #999;
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  bottom: 11px;
  right: 11px;
}

.chat-content-holder-message-holder-message-in-text-time-bg {
  color: #fff;
  background: #52525280;
  border-radius: 30px;
  padding: 5px 10px;
}

.chat-content-holder-message-holder-message-in-file {
  background: #f8f8f8;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.chat-content-holder-message-holder-message-in-file-img {
  width: 100%;
}

.chat-content-holder-message-holder-message-in-file-p {
  color: #1e1e1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}

.chat-content-holder-message-holder-message-in-file-time {
  text-align: right;
  color: #999;
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  position: absolute;
  bottom: 11px;
  right: 11px;
}

.chat-content-holder-message-holder-message-in-file-time-bg {
  color: #fff;
  background: #52525280;
  border-radius: 30px;
  padding: 5px 10px;
}

.chat-content-holder-message-holder .message-out {
  width: auto;
  max-width: 40rem;
  height: auto;
  float: right;
  min-width: 120px;
  clear: both;
  margin: 0 0 0 auto;
  display: inline-block;
}

.chat-content-holder-message-holder-message-out-text {
  font-size: inherit;
  min-width: 120px;
  background: #e4edfd;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-bottom: 2rem;
  margin-left: auto;
  padding: 1.1rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.chat-content-holder-message-holder-message-out-text-p {
  color: #1e1e1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}

.chat-content-holder-message-holder-message-out-text-time {
  text-align: right;
  color: #999;
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  position: absolute;
  bottom: 11px;
  right: 11px;
}

.chat-content-holder-message-holder-message-out-text-time-bg {
  color: #fff;
  background: #52525280;
  border-radius: 30px;
  padding: 5px 10px;
}

.chat-content-holder-message-holder-message-out-text-time-check {
  color: #3369f3;
}

.chat-content-holder-message-holder-message-out-file {
  background: #f8f8f8;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.chat-content-holder-message-holder-message-out-file-img {
  width: 100%;
}

.chat-content-holder-message-holder-message-out-file-p {
  color: #1e1e1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}

.chat-content-holder-message-holder-message-out-time {
  text-align: right;
  color: #999;
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  position: absolute;
  bottom: 11px;
  right: 11px;
}

.chat-content-holder-message-holder-message-out-time-bg {
  color: #fff;
  background: #52525280;
  border-radius: 30px;
  padding: 5px 10px;
}

#message::placeholder {
  color: #966;
  opacity: 1;
}

.error {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-p {
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
}

.error-p-a {
  color: var(--input-border-bottom-color);
}

.error-p-a:hover {
  color: var(--main-input-placeholder);
}

:root {
  --main-input-placeholder-color: #999;
  --main-input-bg: #efefef;
  --main-body-color: #1e1e1e;
  --input-border-bottom-color: #3369f3;
  --button-bg-color: #eaeaea;
  --button-color: #fff;
  --section-bg-color: #fbfbfb;
  --a-color: red;
}

button {
  box-shadow: none;
  cursor: pointer;
  border: none;
  outline: none;
}

::placeholder {
  color: var(--main-input-placeholder);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
}

a {
  outline: none;
  text-decoration: none;
}

a:hover {
  color: var(--main-body-color);
}

a:focus {
  box-shadow: none;
  outline: none;
  text-decoration: none;
}

body, html {
  width: 100%;
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  color: var(--main-body-color);
  font-size: 1.2rem;
}

input {
  background: var(--main-input-bg);
  height: 3.2rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: .5rem 2rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

input:focus {
  border: none;
  border-bottom: 1px solid var(--input-border-bottom-color);
  color: var(--main-body-color);
  background: none;
  border-radius: 0;
  outline: none;
  font-size: 1.3rem;
}

section#hero {
  flex-wrap: row nowrap;
  display: flex;
}

form * {
  color: var(--main-body-color);
  letter-spacing: .5px;
  border: none;
  outline: none;
}

.title {
  text-align: center;
  color: var(--main-body-color);
  margin: 0 auto 4rem;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.subtitle {
  text-align: center;
  color: var(--main-body-color);
  margin-bottom: 7rem;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.warn {
  color: red;
  opacity: 0;
  height: 0;
  order: 0;
}

.all-button {
  color: var(--button-color);
  cursor: pointer;
  width: auto;
  text-align: center;
  text-transform: none;
  letter-spacing: 1px;
  max-height: 3.7rem;
  white-space: nowrap;
  background: var(--input-border-bottom-color);
  border: none;
  border-radius: 8px;
  outline: none;
  padding: 0 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 3.7rem;
  text-decoration: none;
  display: inline-block;
}

.all-button:hover {
  background: var(--button-bg-color);
  color: var(--main-body-color);
}

.main {
  width: 100%;
  height: 100%;
}

.d-none {
  display: none;
}

section.profile {
  width: 100%;
  height: 100%;
  display: flex;
}

section.profile .profile-sidebar {
  background: var(--section-bg-color);
  width: 64px;
  border-right: 1px solid var(--button-bg-color);
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.profil-ava {
  width: 13rem;
  height: 13rem;
  background: var(--main-input-bg);
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto 2.1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.profil-ava:before {
  content: "Поменять аватар";
  max-width: 13rem;
  height: 13rem;
  color: var(--button-color);
  text-align: center;
  opacity: 0;
  background: #00000080;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  display: flex;
  position: absolute;
}

.profil-ava:hover:before {
  opacity: 1;
}

.prof-table.d-flex {
  max-width: 51rem;
  flex-direction: column;
  margin-bottom: 4rem;
  display: flex;
}

.prof-table-row:last-child {
  border-bottom: 1px solid var(--button-bg-color);
}

.prof-table.d-flex .prof-table-row {
  border-bottom: 1px solid var(--button-bg-color);
  justify-content: space-between;
  display: flex;
}

.prof-description-value {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}

.prof-table.d-flex .prof-table-row:last-child {
  border: none;
}

.prof-table-col-left {
  text-align: left;
  height: 3.3rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.prof-table-col-left .prof-description-value {
  color: var(--main-body-color);
}

.prof-table-col-right {
  text-align: right;
  height: 3.3rem;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.prof-table-col-right .prof-description-value {
  color: var(--main-input-placeholder);
}

.prof-table.d-flex.data-forma {
  margin-bottom: 0;
}

/*# sourceMappingURL=index.5df25aeb.css.map */
