section.profile {
  display: flex;
  width: 100%;
  height: 100%;
}

section.profile .profile-sidebar {
  flex: 0 0 auto;
  background: var(--section-bg-color);
  width: 64px;

  /* height: 100%; */
  border-right: 1px solid var(--button-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
